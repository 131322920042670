import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const money = (number: number, digits = 0) => {
  return new Intl.NumberFormat("hu", {
    style: "currency",
    currency: "HUF",
    maximumFractionDigits: digits,
  }).format(number);
};

export const floatToFormatted = (number: number, digits = 2) => {
  return new Intl.NumberFormat("hu", {
    maximumFractionDigits: digits,
  }).format(number);
};

export const formattedToFloat = (input: string) => {
  let normalizedNumber = input.replace(/[^0-9,-]/g, "");
  normalizedNumber = normalizedNumber.replace(/,/g, ".");
  const floatNumber = parseFloat(normalizedNumber);

  if (isNaN(floatNumber)) {
    throw new Error("invalid-number");
  }

  return floatNumber;
};

export const normalize = (text: string) => {
  if (!text) {
    return "";
  }
  return text
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const getDate = (date: Date | null, time = false) => {
  if (!date) {
    return "";
  }
  const final = [date.toISOString().split("T")[0]];
  if (time) {
    final.push(date.toLocaleTimeString("hu", { timeStyle: "short" }));
  }
  return final.join(" ");
};
